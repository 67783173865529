import React, { useRef } from 'react';
import TextField from "@material-ui/core/TextField";

export const CiselnyVstup = (props) => {

    const ref = useRef(null);

    return (
        <TextField
            {...props}
            type="number"
            size="small"
            fullWidth
            inputRef={ref}
            onClick={() => ref.current.select()}
        />
    );
}