import React from 'react';
import Slider from "@material-ui/core/Slider";
import { action } from "mobx";
import { observer } from "mobx-react-lite";
import { Blok } from "./blok";
import { CiselnyVstup } from './ciselny-vstup';

export const ModifikatoryObrany = observer(({ store }) => {

    return (
        <Blok title="Modifikátory obrany" background="rgba(106,122,155,0.2)">
            <div>
                <p style={{ marginBottom: 0 }}>Součet bonusu obrany země a štítů (<strong>{store.obrannyBonus}%</strong>):</p>
                <Slider
                    marks={[0, 5, 15, 35].map(b => ({ value: b, label: b }))}
                    value={store.obrannyBonus}
                    onChange={action('nastav obrannyBonus', (e, value) => store.obrannyBonus = value)}
                    valueLabelDisplay="auto"
                    step={5}
                    min={-60}
                    max={95}
                />
                <CiselnyVstup
                    label="Vojenské smlouvy od sousedů:"
                    value={store.vojenske}
                    onChange={action('nastav vojenske',(e) => store.vojenske = Number(e.target.value))}
                />
            </div>
        </Blok>
    );
});
