import React from 'react';
import './app.css';
import { Instance } from "./komponenty/instance";
import { Store } from "./lib/store";

// Ďesňák

// TODO: udelat import/export storu a ukladat ho do localStorage
// .. udelat tak historii, ve ktere by se dalo vracet k predchozim vypoctum
// Vymyslet dynamicke pridavani a mazani instanci


const INSTANCE_1 = 'instance_1';
const INSTANCE_2 = 'instance_2';
const INSTANCE_3 = 'instance_3';
const INSTANCE_4 = 'instance_4';
const INSTANCE_5 = 'instance_5';
const INSTANCE_6 = 'instance_6';

const App = () => {
    const json1 = localStorage.getItem(INSTANCE_1)
    const json2 = localStorage.getItem(INSTANCE_2)
    const json3 = localStorage.getItem(INSTANCE_3)
    const json4 = localStorage.getItem(INSTANCE_4)
    const json5 = localStorage.getItem(INSTANCE_5)
    const json6 = localStorage.getItem(INSTANCE_6)
    return (
        <>
            <div style={{ display: 'flex' }}>
                <Instance store={new Store(INSTANCE_1, json1 ? JSON.parse(json1) : undefined)} />
                <Instance store={new Store(INSTANCE_2, json2 ? JSON.parse(json2) : undefined)} />
                <Instance store={new Store(INSTANCE_3, json3 ? JSON.parse(json3) : undefined)} />
                <Instance store={new Store(INSTANCE_4, json4 ? JSON.parse(json4) : undefined)} />
                <Instance store={new Store(INSTANCE_5, json4 ? JSON.parse(json5) : undefined)} />
                <Instance store={new Store(INSTANCE_6, json4 ? JSON.parse(json6) : undefined)} />
                {/* <Instance store={new Store()} alt /> */}
            </div>
            <div style={{ textAlign: 'left', padding: 10 }}>
                Pridani noveho hrdiny do oblibenych:
                <ol>
                    <li>Zadej hodnoty do poli utok, utok%, obrana a obrana%</li>
                    <li>Pouzij tlacitko/ikonku vedle seznamu hrdinu.</li>
                    <li>Zadej jmeno hrdiny a pripadne i cislo obrazku ve formatu: <code>JMENO,CISLO</code> (napr.: "Draaak,72"). Cislo obrazku hrdiny se da vycist z URL adresy obrazku.</li>
                </ol>
                Smazani hrdiny z oblibenych:
                <ol>
                    <li>Drz klavesu Shift a klikni na portret hrdiny - bude okamzite smazan.</li>
                </ol>
            </div>
        </>
    );
}

export default App;
