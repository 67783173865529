import { autorun, makeAutoObservable } from "mobx";
import { DB } from "./db";
import { Decimal } from "decimal.js";

export class Store {

    constructor(uid, seed) {
        this.poznamka = seed?.poznamka || '';
        this.rasa = seed?.rasa || DB[0];
        this.jednicky = seed?.jednicky ?? 6;
        this.dvojky = seed?.dvojky ?? 0;
        this.trojky = seed?.trojky ?? 2;
        this.obyvatele = seed?.obyvatele ?? 0;
        this.stavby1 = seed?.stavby1 || [0, 0, 1, 0, 0, 0, 0];
        this.stavby2 = seed?.stavby2 || [1, 0, 1, 0, 0, 1, 0];
        this.stavby3 = seed?.stavby3 || [1, 1, 1, 1, 0, 0, 1];
        this.stavby4 = seed?.stavby4 || [0, 0, 0, 0, 0, 0];
        this.stavby5 = seed?.stavby5 || [0, 1, 0, 0, 0, 0];
        this.stavby6 = seed?.stavby6 || [1, 1, 0, 0, 0, 1];
        this.bonusRytir = seed?.bonusRytir || false;
        this.bonusRasovy = seed?.bonusRasovy || false;
        this.bonusZrusena = seed?.bonusZrusena || false;
        this.bonusStrach = seed?.bonusStrach || false;
        this.obrannyBonus = seed?.obrannyBonus ?? 0;
        this.vybranyHrdina = seed?.vybranyHrdina || undefined;
        this.utokHrdiny = seed?.utokHrdiny ?? 0;
        this.utokHrdinyBonus = seed?.utokHrdinyBonus ?? 0;
        this.obranaHrdiny = seed?.obranaHrdiny ?? 0;
        this.obranaHrdinyBonus = seed?.obranaHrdinyBonus ?? 0;
        this.vojenske = seed?.vojenske || 0;

        this.oblibeniHrdinove = seed?.oblibeniHrdinove || [
            {
                jmeno: 'Přísňák',
                obrazek: '70',
                utok: 20,
                utokBonus: 42,
                obrana: 200,
                obranaBonus: 60
            },
            {
                jmeno: 'Ametrine',
                obrazek: '72',
                utok: 18,
                utokBonus: 41,
                obrana: 172,
                obranaBonus: 70
            },
            {
                jmeno: 'Shakugor',
                obrazek: '63',
                utok: 14,
                utokBonus: 36,
                obrana: 168,
                obranaBonus: 52
            },
            {
                jmeno: 'Tarkgor',
                obrazek: '65',
                utok: 12,
                utokBonus: 34,
                obrana: 172,
                obranaBonus: 63
            },
            {
                jmeno: 'Fuj!',
                obrazek: '74',
                utok: 14,
                utokBonus: 20,
                obrana: 97,
                obranaBonus: 32
            },
            {
                jmeno: 'Aegis of the Legion',
                obrazek: '1',
                utok: 7,
                utokBonus: 22,
                obrana: 161,
                obranaBonus: 50
            },
            // {
            //     jmeno: 'Kikot',
            //     obrazek: '57',
            //     utok: 9,
            //     utokBonus: 10,
            //     obrana: 160,
            //     obranaBonus: 40
            // },
        ];

        makeAutoObservable(this);

        autorun(() => {
            if (uid) localStorage.setItem(uid, JSON.stringify(this));
        });
    }

    nastavUtocnouStavbu = (index, indexStavby) => {
        const stavby = this[`stavby${index}`];
        stavby[indexStavby] = stavby[indexStavby] ? 0 : 1;

        // ... line reseni limitace vyberu jedine pevnosti
        if (indexStavby === 4) {
            stavby[5] = 0;
            stavby[6] = 0;
        } else if (indexStavby === 5) {
            stavby[4] = 0;
            stavby[6] = 0;
        } else if (indexStavby === 6) {
            stavby[4] = 0;
            stavby[5] = 0;
        }
    }

    nastavObrannouStavbu = (index, indexStavby) => {
        const stavby = this[`stavby${index}`];
        stavby[indexStavby] = stavby[indexStavby] ? 0 : 1;

        // ... line reseni limitace vyberu jedine pevnosti
        if (indexStavby === 2) {
            stavby[3] = 0;
            stavby[4] = 0;
            stavby[5] = 0;
        } else if (indexStavby === 3) {
            stavby[2] = 0;
            stavby[4] = 0;
            stavby[5] = 0;
        } else if (indexStavby === 4) {
            stavby[2] = 0;
            stavby[3] = 0;
            stavby[5] = 0;
        } else if (indexStavby === 5) {
            stavby[2] = 0;
            stavby[3] = 0;
            stavby[4] = 0;
        }
    }

    resetujArmadu = () => {
        this.jednicky = 0;
        this.dvojky = 0;
        this.trojky = 0;
        this.obyvatele = 0;
    };

    resetujHrdinu = () => {
        this.utokHrdiny = 0;
        this.utokHrdinyBonus = 0;
        this.obranaHrdiny = 0;
        this.obranaHrdinyBonus = 0;
        this.vybranyHrdina = undefined;
    };

    vyberOblibenehoHrdinu = (hrdina) => {
        this.vybranyHrdina = hrdina.jmeno;
        this.utokHrdiny = hrdina.utok;
        this.utokHrdinyBonus = hrdina.utokBonus;
        this.obranaHrdiny = hrdina.obrana;
        this.obranaHrdinyBonus = hrdina.obranaBonus;
    };

    smazOblibenehoHrdinu = (hrdina) => {
        this.oblibeniHrdinove = this.oblibeniHrdinove.filter(h => h.jmeno !== hrdina.jmeno);
    }

    spocitejUtok = (index) => {
        const stavby = this[`stavby${index}`];

        let utok = new Decimal(
            this.jednicky * this.rasa.jednotky[0].utok
            + this.dvojky * this.rasa.jednotky[1].utok
            + this.trojky * this.rasa.jednotky[2].utok
        );

        const bonusHrdiny = new Decimal(100 + (isFinite(this.utokHrdinyBonus) ? this.utokHrdinyBonus : 0) + (this.bonusRasovy ? 20 : 0)).div(100);
        utok = utok.times(bonusHrdiny);

        // aplikace malusu za strach
        if (this.bonusStrach) utok = utok.times(0.8);
        utok = utok.floor();

        // aplikace fixniho utoku hrdiny
        utok = utok.plus(this.utokHrdiny);

        // modlitebna
        if (stavby[0] && utok >= 100) utok = utok.plus(30);

        // katapult
        if (stavby[2] && utok >= 200) utok = utok.plus(60);

        // pevnosti
        let procentualniBonus = new Decimal(1);
        if (stavby[1]) procentualniBonus = procentualniBonus.plus(0.05);
        if (stavby[3]) procentualniBonus = procentualniBonus.plus(0.1);
        if (stavby[4]) procentualniBonus = procentualniBonus.plus(0.1);
        if (stavby[5]) procentualniBonus = procentualniBonus.plus(0.25);
        if (stavby[6]) procentualniBonus = procentualniBonus.plus(0.5);

        // uprava kvuli js double-precision floating point
        utok = utok.times(procentualniBonus).floor();

        // aplikace malusu za zrusenou smlouvu
        if (this.bonusZrusena) utok = utok.times(0.7).floor();

        // aplikace bonusu za rytire 
        if (this.bonusRytir) utok = utok.times(1.1).floor();

        return utok.toNumber();
    }

    spocitejObranu = (index) => {
        const stavby = this[`stavby${index}`];

        let obrana = new Decimal(
            this.jednicky * this.rasa.jednotky[0].obrana
            + this.dvojky * this.rasa.jednotky[1].obrana
            + this.trojky * this.rasa.jednotky[2].obrana
        );

        const bonusHrdiny = new Decimal(100 + (isFinite(this.obranaHrdinyBonus) ? this.obranaHrdinyBonus : 0)).div(100);
        obrana = obrana.times(bonusHrdiny);
        obrana = obrana.floor();
        obrana = obrana.plus(this.obranaHrdiny);

        // pevnosti
        let procentualniObrana = new Decimal(1);
        if (stavby[0]) procentualniObrana = procentualniObrana.plus(0.05);
        if (stavby[2]) procentualniObrana = procentualniObrana.plus(0.1);
        if (stavby[3]) procentualniObrana = procentualniObrana.plus(0.2);
        if (stavby[4]) procentualniObrana = procentualniObrana.plus(0.5);
        if (stavby[5]) procentualniObrana = procentualniObrana.plus(1);

        // aplikace bonusu zeme a stitu
        procentualniObrana = procentualniObrana.plus(new Decimal(this.obrannyBonus).div(100));
        obrana = obrana.times(procentualniObrana);

        console.log('ppp', this.vojenske);
        obrana = obrana.plus(this.vojenske || 0);
        obrana = obrana.plus(this.obyvatele);

        // vozy
        if (stavby[1]) obrana = obrana.plus(100);
        obrana = obrana.floor();

        return obrana.toNumber();
    }

    spocitejVojenskou = () => {
        let obrana = new Decimal(
            this.jednicky * this.rasa.jednotky[0].obrana
            + this.dvojky * this.rasa.jednotky[1].obrana
            + this.trojky * this.rasa.jednotky[2].obrana
        );
        return obrana.times(0.3).floor().toNumber();
    }

    ulozHrdinu = () => {
        const vstup = window.prompt('Zadej jméno hrdiny:');
        if (!vstup) return;

        const [jmeno, obrazek] = vstup.split(',');
        if (!jmeno) return;

        let novyHrdina = {
            jmeno: jmeno.trim(),
            obrazek: (obrazek && obrazek.trim()) || '68',
            utok: this.utokHrdiny,
            utokBonus: this.utokHrdinyBonus,
            obrana: this.obranaHrdiny,
            obranaBonus: this.obranaHrdinyBonus,
        }

        this.oblibeniHrdinove = [...this.oblibeniHrdinove, novyHrdina];
    }
}