import React from 'react';
import TextField from "@material-ui/core/TextField";
import { action } from "mobx";
import { observer } from "mobx-react-lite";
// import { Autocomplete } from "@material-ui/lab";
// import { NAZVY_ZEMI } from "../lib/db";
import { IconButton } from "@material-ui/core";
import ClearIcon from "@material-ui/icons/Clear";

export const Poznamka = observer(({ store }) => {
    return (
        <div style={{ marginBottom: 10 }}>
            <TextField
                fullWidth
                placeholder={'Poznámka (např. název země)'}
                value={store.poznamka}
                onChange={action('nastav poznamku', event => {
                    store.poznamka = event.target.value;
                })}
                InputProps={{
                    endAdornment:
                        <IconButton
                            aria-label="reset"
                            onClick={action('resetuj poznamku', () => {
                                store.poznamka = '';
                            })}
                            style={{ height: 'min-content' }}
                        >
                            <ClearIcon/>
                        </IconButton>
                }}
            />
            {/*<Autocomplete*/}
            {/*    id="autocomplete"*/}
            {/*    freeSolo*/}
            {/*    value={store.poznamka}*/}
            {/*    onChange={action('nastav poznamku', (event, value) => {*/}
            {/*        store.poznamka = value;*/}
            {/*    })}*/}
            {/*    options={NAZVY_ZEMI}*/}
            {/*    renderInput={(params) => (*/}
            {/*        <TextField*/}
            {/*            {...params}*/}
            {/*            label="Poznámka (např. název země)"*/}
            {/*        />*/}
            {/*    )}*/}
            {/*/>*/}
        </div>
    );
});