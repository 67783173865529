import React from 'react';
import { DB } from "../lib/db";
import styles from "./rasa.module.css";
import { action } from "mobx";
import { observer } from "mobx-react-lite";
import { Blok } from "./blok";

export const Rasa = observer(({store }) => {
    const getClass = (isActive) => isActive ? `${styles.tile} ${styles.active}` : styles.tile;
    return (
        <Blok title={`Rasa - ${store.rasa.nazev}`}>
            <div className={styles.root}>
                {
                    DB.map(rasa =>
                        <div
                            key={rasa.id}
                            className={getClass(store.rasa.id === rasa.id)}
                            onClick={action('nastav rasu',() => store.rasa = rasa)}
                        >
                            <img src={rasa.obrazek} alt={rasa.nazev} width="35" height="35" />
                            <div className={styles.ram} />
                            {/*{rasa.nazev.substr(0, 3)}*/}
                        </div>
                    )
                }
            </div>
        </Blok>
    );
});