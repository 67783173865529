import React from 'react';
import { IconButton } from "@material-ui/core";
import ClearIcon from "@material-ui/icons/Clear";
import { OblibeniHrdinove } from "./oblibeni-hrdinove";
import { action } from "mobx";
import { observer } from "mobx-react-lite";
import { Blok } from "./blok";
import { CiselnyVstup } from "./ciselny-vstup";

export const Hrdina = observer(({ store }) => {
    return (
        <Blok title="Hrdina">
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: 8 }}>
                <CiselnyVstup
                    label="útok"
                    color="secondary"
                    value={store.utokHrdiny}
                    onChange={action('nastav utok hrdiny', (e) => store.utokHrdiny = parseInt(e.target.value) || 0)}
                />
                <CiselnyVstup
                    label="útok %"
                    value={store.utokHrdinyBonus}
                    onChange={action('nastav utok% hrdiny', (e) => store.utokHrdinyBonus = parseInt(e.target.value) || 0)}
                />
                <CiselnyVstup
                    label="obrana"
                    color="secondary"
                    value={store.obranaHrdiny}
                    onChange={action('nastav obranu hrdiny', (e) => store.obranaHrdiny = parseInt(e.target.value) || 0)}
                />
                <CiselnyVstup
                    label="obrana %"
                    value={store.obranaHrdinyBonus}
                    onChange={action('nastav obranu% hrdiny', (e) => store.obranaHrdinyBonus = parseInt(e.target.value) || 0)}
                />
                <IconButton aria-label="reset" onClick={store.resetujHrdinu} style={{ height: 'min-content' }}>
                    <ClearIcon/>
                </IconButton>
            </div>
            <OblibeniHrdinove store={store} />
        </Blok>
    );
});