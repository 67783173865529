import React from 'react';
import FormControl from "@material-ui/core/FormControl";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import { action } from "mobx";
import { observer } from "mobx-react-lite";
import { Blok } from "./blok";

export const ModifikatoryUtoku = observer(({ store }) => {

    const handleChange = action('nastav bonus', (event) => {
        store[event.target.name] = event.target.checked;
    });

    return (
        <Blok title="Modifikátory útoku" background="rgba(255,255,130,0.3)">
            <div style={{ marginLeft: 7 }}>
                <FormControl component="fieldset">
                    <FormGroup row>
                        <FormControlLabel
                            control={<Switch checked={store.bonusRytir} onChange={handleChange} name="bonusRytir" size="small" />}
                            label="Rytíř (+10%)"
                        />
                        <FormControlLabel
                            control={<Switch checked={store.bonusRasovy} onChange={handleChange} name="bonusRasovy" size="small" />}
                            label="R. bonus (+20%)"
                        />
                        <FormControlLabel
                            control={<Switch checked={store.bonusZrusena} onChange={handleChange} name="bonusZrusena" size="small" />}
                            label="Zrušená (-30%)"
                        />
                        <FormControlLabel
                            control={<Switch checked={store.bonusStrach} onChange={handleChange} name="bonusStrach" size="small" />}
                            label="Strach (-20%)"
                        />
                    </FormGroup>
                </FormControl>
            </div>
        </Blok>
    );
});
