import React from 'react';
import styles from './oblibeni-hrdinove.module.css';
import { observer } from "mobx-react-lite";
import { IconButton, Tooltip } from "@material-ui/core";
import { SaveAlt } from "@material-ui/icons";

export const OblibeniHrdinove = observer(({ store }) => {
    const getClass = (isActive) => isActive ? `${styles.tile} ${styles.active}` : styles.tile;
    return (
        <div className={styles.root}>
            {
                store.oblibeniHrdinove.map(hrdina =>
                    <Tooltip title={hrdina.jmeno} key={hrdina.jmeno} >
                        <div className={getClass(store.vybranyHrdina === hrdina.jmeno)}
                             onClick={(event) => {
                                 event.shiftKey
                                     ? store.smazOblibenehoHrdinu(hrdina)
                                     : store.vyberOblibenehoHrdinu(hrdina);
                             }}>
                            <img
                                src="https://www.darkelf.cz/images/h/frame.gif"
                                alt="pozadi"
                                style={{ position: 'absolute', top: 0, left: 0 }}
                            />
                            <img
                                src={`https://www.darkelf.cz/images/h/${hrdina.obrazek}.gif`}
                                alt={hrdina.jmeno}
                                title={hrdina.jmeno}
                                style={{ position: 'absolute', top: 13, left: 13 }}
                            />
                            <div className={styles.ram}/>
                        </div>
                    </Tooltip>
                )
            }
            <Tooltip title="Ulož hodnoty jako nového hrdinu.">
                <IconButton onClick={store.ulozHrdinu}>
                    <SaveAlt/>
                </IconButton>
            </Tooltip>
        </div>
    );
});