import React from 'react';
import styles from './stavby.module.css';
import { observer } from "mobx-react-lite";
import { Blok } from "./blok";

const stavby = [
    { src: 'https://www.darkelf.cz/images/m/b40.gif', alt: 'Polní modlitebna' },
    { src: 'https://www.darkelf.cz/images/m/b85.gif', alt: 'Kamenné cesty' },
    { src: 'https://www.darkelf.cz/images/m/b60.gif', alt: 'Katapult"' },
    { src: 'https://www.darkelf.cz/images/m/b120.gif', alt: 'Aréna"' },
    { src: 'https://www.darkelf.cz/images/m/0p3.gif', alt: 'Malá pevnost' },
    { src: 'https://www.darkelf.cz/images/m/0p4.gif', alt: 'Střední pevnost' },
    { src: 'https://www.darkelf.cz/images/m/0p5.gif', alt: 'Velká pevnost' },
]

export const Utoky = observer(({ store }) => {
    return (
        <Blok title="Útoky" background="rgba(255,255,130,0.5)">
            <div className={styles.stavby}>
                {
                    stavby.map((s, i) =>
                        <img
                            src={s.src}
                            alt={s.alt}
                            key={s.src}
                            className={store.stavby1[i] ? styles.active : undefined}
                            onClick={() => store.nastavUtocnouStavbu(1, i)}
                        />
                    )
                }
                <strong style={{ textAlign: 'right' }}>{store.spocitejUtok(1)}</strong>
            </div>
            <div className={styles.stavby}>
                {
                    stavby.map((s, i) =>
                        <img
                            src={s.src}
                            alt={s.alt}
                            key={s.src}
                            className={store.stavby2[i] ? styles.active : undefined}
                            onClick={() => store.nastavUtocnouStavbu(2, i)}
                        />
                    )
                }
                <strong style={{ textAlign: 'right' }}>{store.spocitejUtok(2)}</strong>
            </div>
            <div className={styles.stavby}>
                {
                    stavby.map((s, i) =>
                        <img
                            src={s.src}
                            alt={s.alt}
                            key={s.src}
                            className={store.stavby3[i] ? styles.active : undefined}
                            onClick={() => store.nastavUtocnouStavbu(3, i)}
                        />
                    )
                }
                <strong style={{ textAlign: 'right' }}>{store.spocitejUtok(3)}</strong>
            </div>
        </Blok>
    );
});