import React from 'react';
import { IconButton } from "@material-ui/core";
import ClearIcon from '@material-ui/icons/Clear';
import { observer } from "mobx-react-lite";
import { action } from "mobx";
import { Blok } from "./blok";
import { CiselnyVstup } from "./ciselny-vstup";

export const Armada = observer(({ store }) => {
    return (
        <Blok title="Armáda">
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                <CiselnyVstup
                    label="jedničky"
                    color="secondary"
                    value={store.jednicky}
                    onChange={action('nastav jednicky', (e) => store.jednicky = Number(e.target.value))}
                />
                <CiselnyVstup
                    label="dvojky"
                    color="secondary"
                    value={store.dvojky}
                    onChange={action('nastav dvojky',(e) => store.dvojky = Number(e.target.value))}
                />
                <CiselnyVstup
                    label="trojky"
                    color="secondary"
                    value={store.trojky}
                    onChange={action('nastav trojky',(e) => store.trojky = Number(e.target.value))}
                />
                <CiselnyVstup
                    label="obyvatelé"
                    color="primary"
                    value={store.obyvatele}
                    onChange={action('nastav obyvatele',(e) => store.obyvatele = Number(e.target.value))}
                />
                <IconButton aria-label="reset" onClick={store.resetujArmadu} style={{ height: 'min-content' }}>
                    <ClearIcon/>
                </IconButton>
            </div>
        </Blok>
    );
});