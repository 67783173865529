import React from "react";

const css = {
    blok: {
        marginBottom: 4,
        background: 'rgba(68, 39, 5, 0.05)',
        border: '1px solid rgba(68, 39, 5, 0.1)',
        padding: 3,
        // borderRadius: 3,
        textAlign: 'left'
    },
    title: {
        fontSize: '0.8em',
        color: 'brown',
        display: 'inline-block',
        marginBottom: 4,
        padding: 2,
        background: 'rgba(0,0,0,0.05)',
        borderRadius: 2
    }
}

export const Blok = ({ children, title, background }) => <div style={background ? {...css.blok, background} : css.blok}>
    {title && <span style={css.title}>{title}</span>}
    {children}
</div>;