import React from "react";
import Paper from "@material-ui/core/Paper";
import { Poznamka } from "./poznamka";
import { Rasa } from "./rasa";
import { Armada } from "./armada";
import { Hrdina } from "./hrdina";
import { ModifikatoryUtoku } from "./modifikatory-utoku";
import { ModifikatoryObrany } from "./modifikatory-obrany";
import { Utoky } from "./utoky";
import { Obrany } from "./obrany";

export const Instance = ({ store, alt }) => {
    return (
        <Paper style={{ padding: 4, margin: 4, maxWidth: 325 }}>
            <Poznamka store={store} />
            <Rasa store={store} />
            <Armada store={store} />
            <Hrdina store={store} />
            {
                alt
                    ? <>
                        <Obrany store={store} />
                        <ModifikatoryObrany store={store} />
                        <Utoky store={store} />
                        <ModifikatoryUtoku store={store} />

                    </>
                    : <>
                        <Utoky store={store} />
                        <ModifikatoryUtoku store={store} />
                        <Obrany store={store} />
                        <ModifikatoryObrany store={store} />
                    </>
            }
        </Paper>
    );
};