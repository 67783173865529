import React from 'react';
import styles from './stavby.module.css';
import { observer } from "mobx-react-lite";
import { Blok } from "./blok";

const stavby = [
    { src: 'https://www.darkelf.cz/images/m/b105.gif', alt: 'Cvičiště' },
    { src: 'https://www.darkelf.cz/images/m/b100.gif', alt: 'Obranné vozy' },
    { src: 'https://www.darkelf.cz/images/m/0p2.gif', alt: 'Dřevěné hradby' },
    { src: 'https://www.darkelf.cz/images/m/0p3.gif', alt: 'Malá pevnost' },
    { src: 'https://www.darkelf.cz/images/m/0p4.gif', alt: 'Střední pevnost' },
    { src: 'https://www.darkelf.cz/images/m/0p5.gif', alt: 'Velká pevnost' },
]

export const Obrany = observer(({ store }) => {
    return (
        <Blok title="Obrany" background="rgba(106,122,155,0.2)">
            <div className={styles.stavby}>
                <strong style={{ display: 'flex', alignItems: 'center', overflow: 'hidden', whiteSpace: 'nowrap' }}>
                    {store.spocitejObranu(4)}
                    <small title="obrana pro vojenské smlouvy">{'↣ ' + store.spocitejVojenskou()} vs.</small>
                </strong>
                {
                    stavby.map((s, i) =>
                        <img
                            src={s.src}
                            alt={s.alt}
                            key={s.src}
                            className={store.stavby4[i] ? styles.active : undefined}
                            onClick={() => store.nastavObrannouStavbu(4, i)}
                        />
                    )
                }
            </div>
            <div className={styles.stavby}>
                <strong>{store.spocitejObranu(5)}</strong>
                {
                    stavby.map((s, i) =>
                        <img
                            src={s.src}
                            alt={s.alt}
                            key={s.src}
                            className={store.stavby5[i] ? styles.active : undefined}
                            onClick={() => store.nastavObrannouStavbu(5, i)}
                        />
                    )
                }
            </div>
            <div className={styles.stavby}>
                <strong>{store.spocitejObranu(6)}</strong>
                {
                    stavby.map((s, i) =>
                        <img
                            src={s.src}
                            alt={s.alt}
                            key={s.src}
                            className={store.stavby6[i] ? styles.active : undefined}
                            onClick={() => store.nastavObrannouStavbu(6, i)}
                        />
                    )
                }
            </div>
        </Blok>
    );
});